.material-table-container > * {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.material-table-container > * > *, .material-table-container > * > * > *, .material-table-container > * > * > * > *, .material-table-container > * > * > * > * > *, .material-table-container > * thead, .material-table-container > * tr, .material-table-container > * thead th, .material-table-container > * table {
  background-color: transparent !important;
  overflow-y: unset;
}

.material-table-container > * td {
  padding: 0 6px;
  padding-right: 0;
  padding-left: 16px;
  background-color: rgba(0, 0, 0, 0.02);
}

.material-table-container > * tr:hover td {
  background-color: rgba(0, 0, 0, 0.06);
}

.material-table-container > * th {
  padding: 2px 0 5px 16px;
}

.material-table-container > * table {
  counter-reset: rowNumber;
}

.material-table-container > * table tr td:last-child, .material-table-container > * table tr th:last-child {
  right: 0;
  z-index: 999;
}

.material-table-container > * table tr th:last-child {
  z-index: 9999;
}

.material-table-container.--showRowIndex table {
  counter-reset: rowNumber;
}

.material-table-container.--showRowIndex table > tbody > tr:not(:first-child).MuiTableRow-hover {
  counter-increment: rowNumber;
}

.material-table-container.--showRowIndex table > tbody > tr:not(:first-child).MuiTableRow-hover > td:first-child::before {
  content: counter(rowNumber);
}

.material-table-container.--showRowIndex tr:not(:first-child).MuiTableRow-hover > td:first-child {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-width: 90px;
}

button.MuiTab-root {
  font-size: 0.8rem;
  padding: 7px 3px;
  margin-left: 14px;
}

.MuiTableCell-root:last-child > div {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
/*# sourceMappingURL=mui.alt.css.map */