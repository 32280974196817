@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");
:root {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  overflow: hidden;
}

:root {
  --c1: #0893ca;
  --c2: #019d83;
  --ct1: rgba(0, 0, 0, 0.158);
  --ct2: rgba(255, 109, 109, 0.308);
  --text: #ffffff;
  --header-h: 4em;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body, #root {
  min-height: 100vh;
}

a {
  color: inherit;
}

#vertical-layout {
  min-height: 100vh;
  max-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
}

#horizontal-layout {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

#container {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 14px !important;
  height: 14px !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 15px !important;
  background: rgba(110, 110, 110, 0.212) !important;
}

*:hover > ::-webkit-scrollbar-thumb {
  background: rgba(110, 110, 110, 0.452) !important;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(114, 114, 114, 0.692) !important;
}

select::-webkit-scrollbar-thumb {
  border-width: 2px !important;
}

.primaryColor, .primaryColor * {
  color: #0984e3 !important;
}

.toast-alter {
  border-radius: 7px !important;
  padding: 5px 5px 5px 5px !important;
  min-height: 52px !important;
}
/*# sourceMappingURL=global.css.map */