.guestForm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 100%;
}

.guestForm > * {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  max-width: 350px;
}

.guestForm form {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

.guestForm__title {
  text-transform: uppercase;
}

.guestForm__bottomLink {
  font-size: 14px;
}
/*# sourceMappingURL=noauth.css.map */