@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap);
:root {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  overflow: hidden;
}

:root {
  --c1: #0893ca;
  --c2: #019d83;
  --ct1: rgba(0, 0, 0, 0.158);
  --ct2: rgba(255, 109, 109, 0.308);
  --text: #ffffff;
  --header-h: 4em;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, #root {
  min-height: 100vh;
}

a {
  color: inherit;
}

#vertical-layout {
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

#horizontal-layout {
  flex: 1 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

#container {
  flex: 1 1;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 14px !important;
  height: 14px !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 15px !important;
  background: rgba(110, 110, 110, 0.212) !important;
}

*:hover > ::-webkit-scrollbar-thumb {
  background: rgba(110, 110, 110, 0.452) !important;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(114, 114, 114, 0.692) !important;
}

select::-webkit-scrollbar-thumb {
  border-width: 2px !important;
}

.primaryColor, .primaryColor * {
  color: #0984e3 !important;
}

.toast-alter {
  border-radius: 7px !important;
  padding: 5px 5px 5px 5px !important;
  min-height: 52px !important;
}
/*# sourceMappingURL=global.css.map */
@-webkit-keyframes autofill {
  0%, 100% {
    color: #666 !important;
    background: none !important;
  }
}

@keyframes autofill {
  0%, 100% {
    color: #666 !important;
    background: none !important;
  }
}

input {
  -webkit-box-shadow: unset !important;
}

input:-webkit-autofill {
  -webkit-animation-delay: 0s !important;
  /* Safari support - any positive time runs instantly */
  -webkit-animation-name: autofill !important;
  -webkit-animation-fill-mode: both !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s !important;
  transition: background-color 5000s ease-in-out 0s !important;
}
/*# sourceMappingURL=misc.css.map */
.material-table-container > * {
  box-shadow: none !important;
}

.material-table-container > * > *, .material-table-container > * > * > *, .material-table-container > * > * > * > *, .material-table-container > * > * > * > * > *, .material-table-container > * thead, .material-table-container > * tr, .material-table-container > * thead th, .material-table-container > * table {
  background-color: transparent !important;
  overflow-y: unset;
}

.material-table-container > * td {
  padding: 0 6px;
  padding-right: 0;
  padding-left: 16px;
  background-color: rgba(0, 0, 0, 0.02);
}

.material-table-container > * tr:hover td {
  background-color: rgba(0, 0, 0, 0.06);
}

.material-table-container > * th {
  padding: 2px 0 5px 16px;
}

.material-table-container > * table {
  counter-reset: rowNumber;
}

.material-table-container > * table tr td:last-child, .material-table-container > * table tr th:last-child {
  right: 0;
  z-index: 999;
}

.material-table-container > * table tr th:last-child {
  z-index: 9999;
}

.material-table-container.--showRowIndex table {
  counter-reset: rowNumber;
}

.material-table-container.--showRowIndex table > tbody > tr:not(:first-child).MuiTableRow-hover {
  counter-increment: rowNumber;
}

.material-table-container.--showRowIndex table > tbody > tr:not(:first-child).MuiTableRow-hover > td:first-child::before {
  content: counter(rowNumber);
}

.material-table-container.--showRowIndex tr:not(:first-child).MuiTableRow-hover > td:first-child {
  align-items: center;
  min-width: 90px;
}

button.MuiTab-root {
  font-size: 0.8rem;
  padding: 7px 3px;
  margin-left: 14px;
}

.MuiTableCell-root:last-child > div {
  justify-content: flex-end;
}
/*# sourceMappingURL=mui.alt.css.map */
.guestForm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  height: 100%;
}

.guestForm > * {
  flex: 1 1;
  max-width: 350px;
}

.guestForm form {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

.guestForm__title {
  text-transform: uppercase;
}

.guestForm__bottomLink {
  font-size: 14px;
}
/*# sourceMappingURL=noauth.css.map */
