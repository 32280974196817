@-webkit-keyframes autofill {
  0%, 100% {
    color: #666 !important;
    background: none !important;
  }
}

@keyframes autofill {
  0%, 100% {
    color: #666 !important;
    background: none !important;
  }
}

input {
  -webkit-box-shadow: unset !important;
}

input:-webkit-autofill {
  -webkit-animation-delay: 0s !important;
  /* Safari support - any positive time runs instantly */
  -webkit-animation-name: autofill !important;
  -webkit-animation-fill-mode: both !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s !important;
  transition: background-color 5000s ease-in-out 0s !important;
}
/*# sourceMappingURL=misc.css.map */